import Constants from "./constants";
import React from 'react';
import CustomLink from "./customlink.js"

var styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: Constants.footerHeight,
    position: 'relative',
    backgroundColor: Constants.colors.darkGray,
  },
  textStyle: {
    color: Constants.colors.gray, 
    textAlign: 'center', 
    fontSize: '75%', 
    maxWidth: '80%',
    marginTop: 0,
  },
};

const Footer = () => {
  var facebook = 
    <div style={{display: 'flex', alignItems: 'center'}}>
      <img style={{width: 60}} src={'/img/icons/facebook.png'} alt="facebook"/>
    </div>;
  var instagram = 
    <div style={{display: 'flex', alignItems: 'center'}}>
      <img style={{width: 60}} src={'/img/icons/instagram.png'} alt="instagram"/>
    </div>;
  var youtube = 
    <div style={{display: 'flex', alignItems: 'center'}}>
      <img style={{width: 60}} src={'/img/icons/youtube.png'} alt="youtube"/>
    </div>;

  return (
    <div style={styles.container}>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
        <CustomLink
          of={instagram}
          url={'https://www.instagram.com/chase_morrin'}
          style={{opacity: 1}}
          hoverStyle={{opacity: .3}}
        />
        <CustomLink
          of={facebook}
          url={'https://www.facebook.com/chase.piano'}
          style={{opacity: 1}}
          hoverStyle={{opacity: .3}}
        />
        <CustomLink
          of={youtube}
          url={'https://www.youtube.com/channel/UCswjpRlbmIRw4y9QLtGUeNQ'}
          style={{opacity: 1}}
          hoverStyle={{opacity: .3}}
        />
      </div>
      <p style={styles.textStyle}>
        <i className="fa fa-copyright"></i>
        {' Chase Morrin 2016. All Rights Reserved. Design by Chase Morrin'}
      </p>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
        
      </div>
    </div>
  );
};

export default Footer;