import AlbumText from '../data/AlbumsData';
import Constants from "./constants";
import CustomNavLink  from './customNavLink';
import Radium from "radium";
import React from 'react';

var styles = {
	 albumsContainer: {
    display: 'flex', 
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    position: 'fixed',
    height: '100%',
    marginRight: 20,
    right: 0,
    maxWidth: 200,
    zIndex: 2,
  },
  albumContainer: {
    display: 'flex', 
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 3,
    marginTop: 3,
    marginRight: 3,
    marginLeft: 3,
  },
  text: {
  	fontSize: '80%',
    fontFamily: Constants.texts.headerText,
    paddingRight: 10,
    whiteSpace: 'nowrap',
    zIndex: 12,
    pointerEvents: 'none',
  },
  navLink: {
    border: '2px solid rgba(0, 0, 0, 0)', 
    borderRadius: Constants.albumBorderRadius,
    textDecoration: 'none', 
    width: 80, 
    transition: 'all .1s ease-in', 
    WebkitTransition: 'all .1s ease-in', 
    color: 'rgba(0, 0, 0, 0)',
    marginTop: 3,
  },
};

function AlbumCycler({color = Constants.colors.red}) {
  
  function makeAlbumBox(image, name) {
    return (
      <div style={styles.albumContainer}>
        <p style={styles.text}>{name}</p>
        <img style={{width: 74, borderRadius: Constants.albumBorderRadius/2, boxShadow: Constants.cardShadow,}} src={image}/>    
      </div>
    );
  }

  return (
    <div style={styles.albumsContainer}>
      {AlbumText.albums.map(function(a){ 
      var next = a.Page;
      return (
        <CustomNavLink 
          of={makeAlbumBox(a.Image, a.Name)}
          to={'/Recordings/' + a.Page}
          style={styles.navLink}
          hoverStyle={{border: '2px solid ' + color, borderRadius: Constants.albumBorderRadius, textDecoration: 'none', width: 80, transition: 'all .1s ease-in', WebkitTransition: 'all .1s ease-in', color: color, marginTop: 3,}}
          activeStyle={{border: '2px solid ' + color, borderRadius: Constants.albumBorderRadius, textDecoration: 'none', width: 80, transition: 'all .1s ease-in', WebkitTransition: 'all .1s ease-in', color: 'rgba(0, 0, 0, 0)', marginTop: 3, opacity: 1,}}
        />
      )})}
    </div>
  );
  
}

export default Radium(AlbumCycler);
