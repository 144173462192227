import Constants from './constants';
import Img from './Img';
import Radium from "radium";
import CustomLink from './customlink';
import CustomNavLink from './customNavLink';

//var CustomNavLink = require('./customNavLink.js');
//var CustomLink = require('./customlink.js');

/**
 * Example:
 * <ImgLink 
 *  imgSrc={'img/image.png'}
 *  to={'pageRoute'}
 *  url={'www.url.com'}
 *  style={{position: 'relative', width: '100%'}}
 *  content={<div>any stuff</div>}
 *  sexyCenter={['1', '2']}
 *  tag={['1', '2']}/>
 */

var styles = {
  sexyCenter: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    position: 'absolute', 
    backgroundColor: Constants.colors.transparentBlack, 
    width: '100%', 
    height: '100%',
  },
  cell: {
    opacity: 0, 
    height: '100%', 
    width: '100%', 
    position: 'absolute',
    transition: 'all .1s ease-in',
    WebkitTransition: 'all .1s ease-in',
  },
  cellHover: {
    opacity: 1, 
    height: '100%', 
    width: '100%', 
    position: 'absolute',
    transition: 'all .1s ease-in',
    WebkitTransition: 'all .1s ease-in',
  },
};

const ImgLink = ({imgSrc = null, to = null, url = null, style = null, sexyCenter = null, content = null, tag = null}) => {

  function sexyMap(arr) {
    if (!arr) {return null;}
    return(
      <div style={{display: 'flex', flexDirection: 'column'}}>
        {arr.map(function(x) {return(
          <h1 style={{textAlign: 'center', color: Constants.colors.transparentWhite, fontSize: '120%', margin: 5}}>{x}</h1>
        )})}
      </div>  
    );
  }

  var mainContent = null;
  var mainTag = 
    <div style={{
      position: 'absolute', 
      bottom: 10, 
      width: '100%', 
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center', 
      color: Constants.colors.transparentWhite,
      fontSize: '80%'}}>
      {sexyMap(tag)}
    </div>;

  if (sexyCenter) {
    mainContent = (
      <div style={styles.sexyCenter}>
        {sexyMap(sexyCenter)}      
      </div>
    );
  } else if (content) {
    mainContent = (
      <div style={{
        position: 'absolute', 
        backgroundColor: Constants.colors.transparentBlack, 
        width: '100%', 
        height: '100%', 
        color: Constants.colors.white, 
        borderRadius: Constants.albumBorderRadius,
        boxShadow: Constants.cardShadow,
      }}>
        {content}
        {mainTag}
      </div>
    );
  } else {
    mainContent = (
      <div style={{
        position: 'absolute', 
        backgroundColor: Constants.colors.transparentBlack, 
        width: '100%', 
        height: '100%',
        borderRadius: Constants.albumBorderRadius,
        boxShadow: Constants.cardShadow,
          }}>
        {mainTag}
      </div>
    );
  }

  var link = null;
  if (to && (!to.includes('www') && !to.includes('http'))) {
    link = 
      <CustomNavLink 
        of={mainContent}
        to={to}
        style={styles.cell}
        hoverStyle={styles.cellHover}
      />;
  } else if (to && (to.includes('www') || to.includes('http'))) {
    link = 
      <CustomLink 
        of={mainContent}
        url={to}
        style={styles.cell}
        hoverStyle={styles.cellHover}
      />;
  } else if (url) {
    link = 
      <CustomLink 
        of={mainContent}
        url={url}
        style={styles.cell}
        hoverStyle={styles.cellHover}
      />;
  } else {
    link = 
      <div
        style={[
          styles.cell,
          {':hover': styles.cellHover},
          {backgroundColor: Constants.colors.transparentBlack},
        ]} 
      >
        {mainContent}
      </div>;
  }

  return (
    <div style={style}>
      {link}
      <Img src={imgSrc} rootStyle={{borderRadius: Constants.albumBorderRadius, boxShadow: Constants.cardShadow}} style={{width: '100%'}}/>
    </div>
  );
  
};

export default Radium(ImgLink);