var Constants = {
  colors: {
    red: '#9d2121',
    green: '#7F8830',
    white: '#ffffff',
    black: '#1a1a1a',
    gray: '#bbbbbb',
    darkGray: '#363636',
    transparentWhite: 'rgba(238, 238, 238, .5)',
    semiTransparentWhite: 'rgba(238, 238, 238, .93)',
    transparentGray: 'rgba(204, 204, 204, .6)',
    transparentDarkGray: 'rgba(80, 80, 80, .6)',
    transparentBlack: 'rgba(34, 34, 34, .8)',   
    transparent: 'rgba(34, 34, 34, 0)',
  },
  texts: {
    headerText: 'Century Gothic, Apple Gothic, AppleGothic, sans-serif',
    mainText: 'SourceSansPro, Helvetica, sans-serif',
  },
  headerHeight: 70,
  footerHeight: 130,
  albumCyclerMobileHeight: 40,
  PAD: 20,
  albumBorderRadius: 10,
  cardShadow: '0 2px 13px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  cardShadowBottom: '0 5px 10px -4px rgba(0, 0, 0, 0.4)',
};

export default Constants;