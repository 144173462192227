import Constants from "./helper/constants";
import Img from "./helper/Img";
import $ from "jquery";
import ProduceContent from './helper/functions';
import MediaQuery from 'react-responsive';
import { useEffect } from 'react';
import { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { saveBioData, savePressData } from './redux/sitedataSlice';

var styles = {
  bioPress: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    position: 'relative',
    maxWidth: '100%',
  },
  biography: {
    flex: '1 0 400px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '100%',
  },
  bioCell: {
    maxWidth: 500,
    margin: Constants.PAD,
  },
  bioHeader: {
    textAlign: 'center',
  },
  bioImage: {
    width: '33%',
    float: 'right',
    marginLeft: 5,
    marginBottom: 3,
  },
  press: {
    flex: '1 0 400px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    maxWidth: '100%',
  },
  pressCell: {
    maxWidth: 500,
    margin: Constants.PAD,
  },
  pressHeader: {
    textAlign: 'center',
    color: Constants.colors.white,
  },
  pressTextUnactive: {
    color: Constants.colors.white,
    textDecoration: 'none',
  },
  pressTextActive: {
    color: Constants.colors.gray,
    textDecoration: 'none',
  },
  pressBackground: {
    position: 'absolute', 
    top: 0, 
    height: '100%',
    width: '100%',
    zIndex: -1,
    left: 0,
  },
  pressGradient: {
    position: 'absolute', 
    top: 0, 
    height: '100%',
    width: '100%',
    backgroundImage: 'linear-gradient(rgba(10,10,10,0), rgba(10,10,10,1), rgba(10,10,10,1))',
  },
};

const bioKeys = {
  spreadsheetId: '1QXobTLS9iLgkC8HbwKg1awruDS2y1VLbmFQ2tvw5Gac',
  PublicApiKey: 'AIzaSyB9o13p6a_RHvno6fV9wKSri1Y9rFkSzBA',
};

const pressKeys = {
  spreadsheetId: '1ZGBzP5qsy2NQKNdKArP4fu_yreszc2IJKgwpiXlaUfU',
  PublicApiKey: 'AIzaSyB9o13p6a_RHvno6fV9wKSri1Y9rFkSzBA',
};

var Platform = null;


const Bio = () => {
  const bioDataGlobal = useSelector((state) => state.sitedata.bioData);
  const pressDataGlobal = useSelector((state) => state.sitedata.pressData);
  const dispatch = useDispatch();

  const [biography, setBiography] = useState([]);
  const [press, setPress] = useState([]);

  useEffect(() => {
    if (bioDataGlobal) {
      return;
    }

    $.get(
      'https://sheets.googleapis.com/v4/spreadsheets/'
      + bioKeys.spreadsheetId
      + '/values/A2:D20' 
      + '?key='
      + bioKeys.PublicApiKey,
      function(result) {
        if (true) {
          setBiography(result.values);
          dispatch(saveBioData(result.values));
        }
    });

    $.get(
      'https://sheets.googleapis.com/v4/spreadsheets/'
      + pressKeys.spreadsheetId
      + '/values/A2:E35' 
      + '?key='
      + pressKeys.PublicApiKey,
      function(result) {
        if (true) {
          setPress(result.values);
          dispatch(savePressData(result.values));
        }
    });
  }, []);

  function Bio(platform) {
    if (bioDataGlobal) {
      return (bioDataGlobal.map(function(x){return(ProduceContent(x[2], x[0], 'white', x[1], x[3]))}));
    }
    else if (biography[0]) {
      return (biography.map(function(x){return(ProduceContent(x[2], x[0], 'white', x[1], x[3]))}));
    }
    else {
      return null;
    }  
  }

  function Press(platform) {
    if (pressDataGlobal) {
      return (pressDataGlobal.map(function(x){return(ProduceContent(x[3], x[0], x[1], x[2], x[4]))}));
    } else if (press[0]) {
      return (press.map(function(x){return(ProduceContent(x[3], x[0], x[1], x[2], x[4]))}));
    } else {
      return null;
    }
  }

  return (
    <div style={styles.bioPress}>
      <MediaQuery minWidth={800}>
        <div style={{height: Constants.headerHeight, width: '100%'}}></div>
      </MediaQuery>
      <div style={styles.biography}>
        <div style={styles.bioCell}>
          <h1 style={styles.bioHeader}>About Chase</h1>
          <MediaQuery minWidth={800}>
            <div>
              {Bio('desktop')}
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={800}>
            <div>
              {Bio('mobile')}
            </div>
          </MediaQuery>
            
        </div>
      </div>
      <div style={styles.press}>

        <MediaQuery minWidth={800}>
          <div style={styles.pressCell}>
            <h1 style={styles.pressHeader}>Press</h1>
            {Press('desktop')}
          </div>
        
          <div style={styles.pressBackground}>
            <div style={styles.pressGradient}></div>
            <Img style={{width: '100%', zIndex: -3}} src={'img/pagephotos/pressbackground.jpg'}/>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={800}>
          <div style={styles.pressCell}>
            <h1 style={styles.pressHeader}>Press</h1>
            {Press('mobile')}
          </div>
        </MediaQuery>
        <div style={{position: 'absolute', top: 0, height: '100%', width: '100%', backgroundColor: Constants.colors.black, zIndex: -2}}></div>
      </div>
    </div>
  );

};

export default Bio;