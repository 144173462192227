import Constants from '../helper/constants';

var AlbumsData = {"albums":[
    {"Name":"The Global Suites", 
    "Image":"/img/albums/globalsuitescover.jpg", 
    "Page":"TheGlobalSuites",
    "Description":"The Ize Trio’s tradition-bridging debut 'The Global Suites' showcases three master musicians from diverse backgrounds with special guest John Patitucci.", 
    "Subtitle":"Ize Trio: Chase Morrin, George Lernis, Naseem Alatrash",
    "BuyAt":[
        {"Name":"Music", "Icon": "fa fa-apple fa-2x", "Link":"https://music.apple.com/us/album/ize-trio-the-global-suites/1747690784"},
        {"Name":"Amazon", "Icon": "fa fa-amazon fa-lg", "Link":"https://amazon.com/music/player/albums/B0D4JVCYQF?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_QohwlA1BoFe1tiHNoGy3jto2p"},
        {"Name":"Spotify", "Icon": "fa fa-spotify fa-2x", "Link":"https://open.spotify.com/album/0QE6f8DJCj0HYUJWJ3kSF4?si=ZABw0hcvQYqPZ7mdcugqWQ"},

    ],
    "BackgroundColor":"#6F4E37",
    "AccentColor":"#C4A484",
    "SheetID": "1bTk27fuoymLRmCflexfJn8PtEWu-CeZUG4KEU-D-J9g",
    },
    {"Name":"Gapi", 
    "Image":"/img/albums/gapicover.jpeg", 
    "Page":"GapiAlbum",
    "Description":"A unique duo project (Chase Morrin - piano & DoYeon Kim - gayageum) that combines music traditions from America and Korea.", 
    "Subtitle":"Chase Morrin & DoYeon Kim",
    "BuyAt":[
        {"Name":"Music", "Icon": "fa fa-apple fa-2x", "Link":"https://itunes.apple.com/us/album/gapi/id1271256198"},
        {"Name":"Spotify", "Icon": "fa fa-spotify fa-2x", "Link":"https://open.spotify.com/album/3mMjOJdugBd25uZVstf94k"},

    ],
    "BackgroundColor":"#1d315c",
    "AccentColor":"#84caf5",
    "SheetID": "14KkLcZxthHkywE3ODMtQ4kTIXHwuwjaueYM-FvTK_wE",
    },
    {"Name":"Turtle", 
    "Image":"/img/albums/turtlefinal.jpg", 
    "Page":"Turtle",
    "Description":"Chase Morrin Trio, featuring Jongkuk Kim (drums), and Jonathan Chapman (bass). All original music combining fresh, spontaneous sounds with complex composition and tight grooves.", 
    "Subtitle":"Chase Morrin Trio - Chase Morrin, Jonathan Chapman, Jongkuk Kim",
    "BuyAt":[
        {"Name":"Spotify", "Icon": "fa fa-spotify fa-2x", "Link":"https://open.spotify.com/album/0SbOJgZO60cW5dXNtT9PLw"},
        {"Name":"Amazon", "Icon": "fa fa-amazon fa-lg", "Link":"https://www.amazon.com/Turtle-Chase-Morrin-Trio/dp/B01M27DXKI/ref=sr_1_13?ie=UTF8&qid=1479511785&sr=8-13&keywords=chase+morrin"},
        {"Name":"Music", "Icon": "fa fa-apple fa-2x", "Link":"https://itunes.apple.com/us/album/turtle/id1167748652"},
    ],
    "BackgroundColor": Constants.colors.white,
    "AccentColor": Constants.colors.red,
    "SheetID": "1TouxXdG2d4z_RN17EFoMn9e3hOq-4EkxH74C6EjHD-8", 
    },
    {"Name":"The Corn Knight", 
    "Image":"/img/albums/corncover.jpg", 
    "Page":"TheCornKnight",
    "Description":"An hour long collaborative story piece with marimbist Yaniv Yacoby, informed by a fictional narrative and composed of many short pieces, like chapters in a book, influenced by Irish and American Folk, Jazz, and Western Classical music traditions.", 
    "Subtitle":"Chase Morrin & Yaniv Yacoby",
    "BuyAt":[
        {"Name":"Spotify", "Icon": "fa fa-spotify fa-2x", "Link":"https://open.spotify.com/album/6ZVHG4K4cnk1t3vX6CKn3v"},
        {"Name":"Amazon", "Icon": "fa fa-amazon fa-lg", "Link":"https://www.amazon.com/gp/product/B01FEAPIIE?ie=UTF8&keywords=corn%20knight&qid=1463067989&ref_=sr_1_2&sr=8-2"},
        {"Name":"Music", "Icon": "fa fa-apple fa-2x", "Link":"https://itunes.apple.com/us/album/the-corn-knight/id1112213726"},
    ],
    "BackgroundColor":"#fff8db",
    "AccentColor": '#a37a08',
    "SheetID": "14JfdN3ArBMyerw8xCH2JJPRKbP_kqdI_-l4XFa_leXo",
    },
    {"Name":"En Com\u00FAn", 
    "Image":"/img/albums/comuncover.jpg", 
    "Page":"EnComun",
    "Description":"Chase Morrin Trio, featuring Fernando Gomez (drums), Tyler Eaton (bass), Jacob Russo (percussion), and Anne Whattoff (vocals). A blend of Cuban and Jazz influences, En Com\u00FAn represents Morrin's debut album as a composer, bandleader, and pianist.", 
    "Subtitle":"Chase Morrin Trio (2010) - Chase Morrin, Fernando Gomez, Tyler Eaton",
    "BuyAt":[
        {"Name":"Spotify", "Icon": "fa fa-spotify fa-2x", "Link":"https://open.spotify.com/album/35sYxuEBVzXzW4u0S8b4ET"},
    	{"Name":"Amazon", "Icon": "fa fa-amazon fa-lg", "Link":"http://www.amazon.com/En-Com%C3%BAn-Chase-Morrin/dp/B006TC2PB0"},
        {"Name":"Music", "Icon": "fa fa-apple fa-2x", "Link":"https://itunes.apple.com/us/album/en-comun/id492805950"},
    ],
    "BackgroundColor":"#192b07",
    "AccentColor":"#a2db76",
    "SheetID": "14L7hgbPiLtJovyPN0F5L8o9HXmxMulV4NCfknbRtfO8",
    },
]};

export default AlbumsData;